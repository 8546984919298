<template>
  <b-tooltip :target="target" :placement="placement">
    {{ message }}
  </b-tooltip>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      required: true,
    },

    placement: {
      type: String,
      default: 'right',
    },

    text: {
      type: String,
      default() {
        return 'no-imex-actions-permission'
      },
    },
  },

  computed: {
    message() {
      return this.$t(this.text)
    },
  },
}
</script>
