<template>
  <b-modal
    id="imex-import-status-modal"
    v-model="open"
    hide-footer
    centered
    no-fade
    size="lg"
  >
    <div class="modal__body tw-p-8">
      <div class="modal__header d-flex align-items-center border-bottom pb-1">
        <feather-icon
          icon="FileIcon"
          class="tw-mr-2"
          color="gold"
        />
        <h4 class="mb-0">
          {{ $t('Import Status') }}
        </h4>
      </div>
      <b-overlay
        spinner-variant="primary"
        class="d-flex flex-column mt-2"
        variant="transparent"
        style="flex: 1"
      >
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="headers"
          primary-key="id"
          show-empty
          :empty-text="$t('imex-empty-import-status')"
          class="table-responsive"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>
          <template #cell(table)="{ item }">
            <span class="text-nowrap text-capitalize">
              {{ item.tableName | replace('_', ' ') }}
            </span>
          </template>
          <template #cell(startDate)="{ item }">
            <span class="text-nowrap">
              {{ item.jobStarted | moment }}
            </span>
          </template>
          <template #cell(status)="{ item }">
            <span class="text-nowrap">
              <feather-icon
                class="tw-mr-1"
                :class="formatStatus(item.status).iconClass"
                :icon="formatStatus(item.status).icon"
                :color="formatStatus(item.status).iconColor"
              />
              {{ formatStatus(item.status).text }}
            </span>
          </template>
          <template #cell(remark)="{ item }">
            <span class="text-nowrap">
              {{ item.exitMessage || $t('N/A') }}
            </span>
          </template>
        </b-table>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import RootEvents from '@/constants/rootEvents'
import imexService from '@/services/imex'
import { ImexStatusText, ImexStatusIcon, ImexStatusIconColor, ImexStatusIconClass } from '@/constants/imex'
import replaceAllFilterMixin from '@/mixins/replaceAllFilter'
import momentFilterMixin from '@/mixins/momentFilter'

export default {
  mixins: [momentFilterMixin, replaceAllFilterMixin],

  data() {
    return {
      open: false,
      loading: false,
      schemaName: '',
      entityType: '',
      items: [],
    }
  },

  computed: {
    headers() {
      return [
        { key: 'table', label: this.$t('Table') },
        {
          key: 'startDate',
          label: this.$t('Start Date'),
        },
        { key: 'status', label: this.$t('Status') },
        { key: 'remark', label: this.$t('Remark') },
      ]
    },
  },

  mounted() {
    this.registerBusEvent(RootEvents.IMEX_IMPORT_STATUS_TOGGLED, data => {
      this.open = data.open ?? true
      this.schemaName = data.schemaName
      this.entityType = data.entityType

      this.reset()
      this.getInitialData()
    })
  },

  destroyed() {
    this.$root.$off(RootEvents.IMEX_IMPORT_STATUS_TOGGLED)
  },

  methods: {
    close() {
      this.open = false
    },

    async getInitialData() {
      this.loading = true
      const { response } = await this.$async(
        imexService.getRecentImports({
          ...this.$lodash.pick(this, ['schemaName', 'entityType']),
        }),
      )

      this.loading = false
      this.items = response?.data || []
    },

    formatStatus(status) {
      return {
        text: this.$t(ImexStatusText[status]),
        icon: ImexStatusIcon[status],
        iconColor: ImexStatusIconColor[status],
        iconClass: ImexStatusIconClass[status],
      }
    },

    reset() {
      this.loading = false
      this.items = []
    },
  },
}
</script>
