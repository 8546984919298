<template>
  <div class="d-flex">
    <div
      v-if="!hideActions"
      class="imex__actions-dropdown mr-1"
    >
      <b-dropdown
        :text="$t('Actions')"
        variant="outline-primary"
        @shown="handleDropdownVisibility(true)"
        @hidden="handleDropdownVisibility(false)"
      >
        <template v-if="canImport && currentRouteName !== 'inquiries'">
          <b-dropdown-item
            id="download-excel-template"
            :data-disabled="!canCreate"
            @click="canCreate && downloadTemplate()"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-1"
            />
            {{ $t('Download Excel Template') }}
          </b-dropdown-item>
          <imex-actions-tooltip
            v-if="dropdownOpen && !canCreate"
            target="download-excel-template"
          />
        </template>
        <template>
          <b-dropdown-item
            id="export-in-excel"
            :data-disabled="!canRead"
            @click="canRead && initiateExport('xlsx')"
          >
            <feather-icon
              icon="LogOutIcon"
              class="mr-1 pz-flip"
            />
            {{ $t('Export in Excel') }}
          </b-dropdown-item>
          <imex-actions-tooltip
            v-if="dropdownOpen && !canRead"
            target="export-in-excel"
          />
        </template>
        <template>
          <b-dropdown-item
            id="export-in-csv"
            :data-disabled="!canRead"
            @click="canRead && initiateExport('csv')"
          >
            <feather-icon
              icon="LogOutIcon"
              class="mr-1 pz-flip"
            />
            {{ $t('Export in CSV') }}
          </b-dropdown-item>
          <imex-actions-tooltip
            v-if="dropdownOpen && !canRead"
            target="export-in-csv"
          />
        </template>
        <template v-if="canImport && currentRouteName !== 'inquiries'">
          <b-dropdown-item
            id="import-in-excel"
            :data-disabled="!canCreate"
            @click="canCreate && $refs.xlsxExplorer.click()"
          >
            <feather-icon
              icon="LogInIcon"
              class="mr-1"
            />
            {{ $t('Import in Excel') }}
          </b-dropdown-item>
          <imex-actions-tooltip
            v-if="dropdownOpen && !canCreate"
            target="import-in-excel"
          />
        </template>
        <template v-if="canImport && currentRouteName !== 'inquiries'">
          <b-dropdown-item
            id="import-in-csv"
            :data-disabled="!canCreate"
            @click="canCreate && $refs.csvExplorer.click()"
          >
            <feather-icon
              icon="LogInIcon"
              class="mr-1"
            />
            {{ $t('Import in CSV') }}
          </b-dropdown-item>
          <imex-actions-tooltip
            v-if="dropdownOpen && !canCreate"
            target="import-in-csv"
          />
        </template>
        <template v-if="canImport && currentRouteName !== 'inquiries'">
          <b-dropdown-item
            id="import-status"
            :data-disabled="!canRead"
            @click="canRead && toggleImportStatusModal({ open: true })"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            {{ $t('Import Status') }}
          </b-dropdown-item>
          <imex-actions-tooltip
            v-if="dropdownOpen && !canRead"
            target="import-status"
          />
        </template>
        <input
          ref="xlsxExplorer"
          type="file"
          class="d-none"
          accept=".xlsx"
          @change="e => handleFileChange('xlsx', e)"
        />
        <input
          ref="csvExplorer"
          type="file"
          class="d-none"
          accept=".csv"
          @change="e => handleFileChange('csv', e)"
        />
      </b-dropdown>
      <imex-import-status-modal />
    </div>
    <ImexAvailableExportsDropdown
      v-if="exports.length"
      :permitted="canRead"
      :list="exports"
    />
  </div>
</template>

<script>
import { ImexStatus } from '@/constants/imex'
import imexService from '@/services/imex'
import RootEvents from '@/constants/rootEvents'
import ImexActionsTooltip from '@/views/shared/Imex/ImexActionsTooltip.vue'
import ImexImportStatusModal from '@/views/shared/Imex/ImexImportStatusModal.vue'
import ImexAvailableExportsDropdown from '@/views/shared/Imex/ImexAvailableExportsDropdown.vue'
import { CustomEntityType } from '@/constants/customEntity'

export default {
  components: {
    ImexActionsTooltip,
    ImexImportStatusModal,
    ImexAvailableExportsDropdown,
  },

  props: {
    hideActions: {
      type: Boolean,
      default: false,
    },
    schemaName: {
      type: String,
      required: true,
    },

    entityType: {
      type: String,
      required: true,
    },

    customEntityType: {
      type: String,
      default: null,
    },

    recentExportEntityType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      CustomEntityType,
      dropdownOpen: false,
      exports: [],
    }
  },

  computed: {
    canRead() {
      return this.$can('Read', 'Bulk_Management')
    },

    canCreate() {
      return this.$can('Create', 'Bulk_Management')
    },

    canImport() {
      return this.customEntityType !== CustomEntityType.VIEW
    },

    currentRouteName() {
      return this.$route.name
    },
  },

  mounted() {
    this.getRecentExports()
  },

  methods: {
    downloadTemplate() {
      imexService.downloadTemplate({
        ...this.$lodash.pick(this, ['schemaName', 'entityType']),
      })
    },

    async getRecentExports() {
      const { response } = await this.$async(
        imexService.getRecentExports(
          {
            schemaName: this.schemaName,
            entityType: this.recentExportEntityType ? this.recentExportEntityType : this.entityType,
          },
          {
            status: ImexStatus.COMPLETED,
          },
        ),
      )

      this.exports = response?.data || []
    },

    handleDropdownVisibility(open = true) {
      this.dropdownOpen = open
    },

    async handleFileChange(type, e) {
      const { files } = e.target
      if (!files.length) return

      const [file] = files
      const formData = new FormData()
      formData.append('file', file)

      await this.$async(
        imexService.initiateImport(
          {
            ...this.$lodash.pick(this, ['schemaName', 'entityType']),
          },
          formData,
        ),
      )

      this.showNotice('import')

      this.$refs[`${type}Explorer`].value = null
    },

    async initiateExport(type) {
      await this.$async(imexService.initiateExport({ ...this.$lodash.pick(this, ['schemaName', 'entityType']) }, { type }))

      this.showNotice('export')
    },

    showNotice(io) {
      this.$swal({
        title: this.$t(`imex-action-${io}-notice`),
        icon: 'info',
        confirmButtonText: this.$t('Close'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      })
    },

    toggleImportStatusModal(data) {
      this.$root.$emit(RootEvents.IMEX_IMPORT_STATUS_TOGGLED, {
        ...data,
        ...this.$lodash.pick(this, ['schemaName', 'entityType']),
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.imex__actions-dropdown {
  [data-disabled='true'] {
    &:hover {
      background: var(--colour--dropdown-item-bg-disabled) !important;
      color: var(--colour--dropdown-item-text-disabled) !important;
      cursor: default !important;
    }
  }
}
</style>
